import React from 'react'
import Quote from '@/components/Quote'

const QuoteContainer: React.FC = () => (
  <Quote
    variant="light"
    name="Mark Slats"
    jobTitle="Adventurer"
    maxWidth="55rem"
  >
    “If you can’t stop thinking about it, stop dreaming and just do it.”
  </Quote>
)

QuoteContainer.propTypes = {}

QuoteContainer.defaultProps = {}

export default QuoteContainer
